import React from 'react'
import HeroSection from '../../HeroSection'
import { homeObjTwo } from '../../Data'

function Services() {
    return (
        <>
            <HeroSection {...homeObjTwo} />
        </>
    )
}

export default Services
